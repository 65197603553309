import React, { Component } from 'react';
import Home from './Home';
import privacyPolicy from './Page/elz-privacidad';
import serverPolicy from './Page/elz-servicio';
import elzCancel from './Page/elz-cancel';
import ErrorInfo from './Page/ErrorInfo';
import { Router, Route } from "react-router-dom";
import {createBrowserHistory} from 'history'
const browserHistory = createBrowserHistory()
class App extends Component {
  render() {
    console.log('----App----')
    return (
      <Router history={browserHistory}>
        <Route exact path="/" component={Home} />
        <Route exact path="/cc-privacidad" component={privacyPolicy} />
        <Route exact path="/cc-servicio" component={serverPolicy} />
        <Route exact path="/cc-cancel" component={elzCancel} />
        <Route exact path="/errorInfo" component={ErrorInfo} />
      </Router>
    );
  }
}

export default App;
