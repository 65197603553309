import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import email from '../assets/imgs/eicon.png';
import emailMobile from '../assets/imgs/mobile/eicon.png';
 function Footer(props) {

  const title = 'Normativas'
  const privacidad = 'Privacidad'
  const terminos = 'Terminos de Uso'
  const emailUrl = 'hola@credicaja.com'
  if(props.isMobile) {
    return (
      <footer id="footer" className="footer-mobile">
         <div className="one-center">
          <div className='title'>{title}</div>
          
          <Router>
            <div className='infos' >
            <Link to="/cc-privacidad" target="_blank">
            {privacidad}
            </Link>
            </div>
            <div className='infos'>
            <Link to="/cc-servicio" target="_blank">{terminos}</Link>
            </div>
          </Router>
        </div>
        <div className="one-center-end">
             
            <div className='title'>CrediCaja</div>
            <div className='infos'>
              <img src={emailMobile} className="contImg" alt='fottOne'/>
              <div>{emailUrl}</div>
            </div>
          </div>
     
    </footer>
    )
  }
  return (
    <footer id="footer" className={'footer'}>
      <div className="bar">
      <div className="one-center">
            <div className='title'></div>
            <div className='infos'>
              <img src={email} className="contImg" alt='fottOne'/>
              <div>{emailUrl}</div>
            </div>
            
          </div>
      <div className="one-center one-center-end">
            <div className='title'>{title}</div>
            <Router>
              <div className='infos' >
              <Link to="/cc-privacidad" target="_blank">
              {privacidad}
              </Link>
              </div>
              <div className='infos'>
              <Link to="/cc-servicio" target="_blank">{terminos}</Link>
              </div>
            </Router>
          </div>
      
         
      </div>
      <div className="bottom-bar">
        Copyright © 2024 | CrediCaja | All Rights Reserved
      </div>
    </footer>
  );
}


export default Footer;
