import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'rc-scroll-anim';
import downLoad from '../assets/imgs/downLoad.png';
import tbg from '../assets/imgs/tbg.png';
import tbgMobile from '../assets/imgs/mobile/tbg.png';
// import title from '../assets/imgs/title.png';
class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  }
  static defaultProps = {
    className: 'banner',
  }
  constructor(props) {
    super(props)
    this.state = {
      appUrl: '',
    }
  }
  componentDidMount(){
    console.log(window.location.origin)
    fetch( window.location.origin + '/descargar',{
      method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(res =>res.json())
    .then((res) => {
      if(res.ret === 0) {
        const { data={} } = res;
        this.setState({
          appUrl: data.url,
        })
      }
    }) 
  }

  
  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { appUrl, andro } = this.state;
    const title = 'Simplificamos el trámite para que obtenga un préstamo sin complicaciones.'
    const subtitle = 'En solo 4 sencillos pasos, puede recibir su dinero en un período de 2 a 24 horas.'
    const desc = 'Inscripción -> envío de datos -> solicitud -> retiro'
    return (
      <Element id="home" component="section" className={(isMobile ? 'block-mobile ': '')}  onChange={navToShadow}>
        <div className={className + (isMobile ? '-mobile ': '')}>
          <div className={`bgWrapper`}>
              <div className={`imgWrapper`}>
                  <img src={isMobile ? tbgMobile: tbg} alt="img" />
                </div>  
              <div className={`content`}>
                <div className={`title`}>{title}</div>
                <div className={`subtitle`}>{subtitle}</div>
                <div className={`desc`}>{desc}</div>
                
                <a target="_blank" rel="noopener noreferrer" href={andro} key="a" className={`word-download`}>
                  <button className="banner-btn"><a    target="_blank" href={appUrl} key="a" rel="noopener noreferrer"><img className="banner-img" src={downLoad} alt='downLoad'/>Descargar CrediCaja </a></button>
                </a>
             
              </div>
             
          </div>
        </div>
      </Element>
    );
  }
}

export default Banner;
